<script setup>
import { ref } from "vue";

const visible = ref(false);
</script>

<template>
    <div class="border-b border-slate-700" itemtype="https://schema.org/Question">
        <h2 
            class="text-slate-400 hover:text-white text-xl py-6 cursor-pointer select-none justify-between flex"
            :class="{
                'text-white' : visible, 
            }"
            itemprop="name" 
            @click="visible = !visible">
            <slot name="question" />
            <v-icon v-if="!visible" class="me-3.5" scale="1.5" name="hi-plus" />
            <v-icon v-if="visible" class="me-3.5" scale="1.5" name="hi-minus" />
        </h2>
        <div 
            class="text-slate-200 overflow-hidden transition-all" 
            :class="{
                    'max-h-0' : !visible,
                    'max-h-[1000px]' : visible,
                }"
            itemscope 
            itemprop="acceptedAnswer"
        >
            <p itemprop="text">
                <slot name="answer" />
            </p>
        </div>
    </div>
</template>